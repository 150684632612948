
let _instance = null;

class GAEventHandler {
    constructor() {}

    registerComponentEvents(component) {
        component.attachReady(function () {
            var controller = component.getRootControl().getController();
            var selectedServiceKey = null;
            var selectedContext = null;

            // Add search event
            controller._searchComponent.attachSearch(function (event) {
              selectedContext = event.getParameter("value");
              if (selectedContext) {
                window.dataLayer.push({
                  context: event.getParameter("value"),
                  service: event.getParameter("sender"),
                  event: "infoHub.search",
                });
              }
            });

            // Add service click events
            controller._servicesComponent.attachServiceSelected(function (
              event
            ) {
              var selectedItem = event.getParameter("selectedItem");
              selectedServiceKey = selectedItem.getService().key;

              window.dataLayer.push({
                action: selectedItem.getService().key + "Click",
                context: selectedItem.getContext().getValue(),
                service: selectedItem.getService().key,
                event: "infoHub.serviceSelected",
              });
            });

            // Contact form submit events
            var eventBus = sap.ui.getCore().getEventBus();
            eventBus.subscribe(
              "ContactForm",
              "Submit",
              function (channel, event, data) {
                window.dataLayer.push({
                  action: selectedServiceKey + "Send",
                  context: selectedContext,
                  service: selectedServiceKey,
                  event: "infoHub.serviceSelected",
                });
              }
            );

            // RMA submit events
            eventBus.subscribe(
              "RMA",
              "Submit",
              function (channel, event, data) {
                window.dataLayer.push({
                  action: selectedServiceKey + "Send",
                  context: selectedContext,
                  service: selectedServiceKey,
                  event: "infoHub.serviceSelected",
                });
              }
            );

            // Feedback events
            eventBus.subscribe(
              "Feedback",
              "Submit",
              function (channel, event, data) {
                window.dataLayer.push({
                  action: "feedbackSend",
                  service: "feedback",
                  event: "infoHub.feedback",
                });
              }
            );

            eventBus.subscribe(
              "Feedback",
              "Open",
              function (channel, event, data) {
                window.dataLayer.push({
                  action: "feedbackClick",
                  service: "feedback",
                  event: "infoHub.feedback",
                });
              }
            );
          });
    }
}

_instance ??= new GAEventHandler;

export default _instance;