let _instance = null;

class StyleHelper {
    constructor(){}

    checkForManualCSSLoading(service){
        const _cssLessServices = new Set(["technicalData", "eplan"]);
        if(!(_cssLessServices.has(service))) return;
        let head = document.getElementsByTagName("head")[0],
            linkTag = document.createElement("link");
            
            linkTag.rel = "stylesheet";
            linkTag.href =
              "https://apps-digital-services.lenze.com/pri/app/component/services/css/style.css";
          
              head.append(linkTag);
      }

}

_instance ??= new StyleHelper();
export default _instance;
